import React, { useEffect, useState } from "react";
import Button from "../UI/Button/Button";
import Card from "../UI/Card/Card";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getVenueTypes } from "../../app/venueMasterSlice";
import { AppDispatch, RootState } from "../../app/store";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { useNavigate, useParams } from "react-router-dom";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../app/globalSlice";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import {
  useSubVenueCreateMutation,
  useSubVenueUpdateMutation,
  useVenueByIdQuery,
  useVenueSetupsByVenueDetailIdQuery,
  useVenueSizeByTypeIdQuery,
} from "../../generated/graphql";
import { z, ZodFormattedError } from "zod";
import Body1 from "../UI/Text/Body/Body1";
import FormFieldViewOnly from "../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../UI/shadcn/dialog";
import { renderZodErrors } from "../../utils/zodErrors";

const SubVenueSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1, "Name cannot be empty."),
  islights: z.boolean(),
  startBuffer: z.number(),
  endBuffer: z.number(),
  parentId: z.number().min(1, "Parent must exist"),
  typeId: z.number().min(1, "Venue type must be present"),
  sizeId: z.number().min(1, "Venue size must be present"),
  venueSetupId: z.number().optional(),
});

type SubVenueSchemaType = z.infer<typeof SubVenueSchema>;

const CreateSubVenue = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const params = useParams();
  const parentId = params.parentId ? +params.parentId : undefined;
  const isEditVenue = params.id ? +params.id : undefined;
  const { venueTypes } = useSelector((state: RootState) => state.venueMaster);

  const [subVenue, setSubVenue] = useState<SubVenueSchemaType>({
    id: isEditVenue,
    name: "",
    islights: false,
    startBuffer: 0,
    endBuffer: 0,
    parentId: parentId ?? 0,
    typeId: 0,
    sizeId: 0,
    venueSetupId: undefined,
  });
  const [openPrePopulateDialog, setOpenPrePopulateDialog] = useState<{
    open: boolean;
    newVenueId: number | undefined;
  }>({
    open: false,
    newVenueId: undefined,
  });
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<SubVenueSchemaType, string>
  >({ _errors: [] });

  const [CreateVenue] = useSubVenueCreateMutation();
  const [UpdateVenue] = useSubVenueUpdateMutation();
  const { data: parentVenue } = useVenueByIdQuery({
    variables: {
      id: parentId!,
    },
    skip: !parentId,
  });
  const {} = useVenueByIdQuery({
    variables: {
      id: isEditVenue!,
    },
    skip: !isEditVenue,
    onCompleted: (data) => {
      setSubVenue({
        id: data.venueById.id,
        name: data.venueById.name,
        islights: data.venueById.islights,
        startBuffer: data.venueById.startBuffer,
        endBuffer: data.venueById.endBuffer,
        parentId: data.venueById.parentId ?? 0,
        typeId: data.venueById.typeId,
        sizeId: data.venueById.sizeId,
        venueSetupId: data.venueById.venueSetupId ?? undefined,
      });
    },
  });

  const { loading: loadingVenueSizes, data: venueSizeData } =
    useVenueSizeByTypeIdQuery({
      variables: {
        venueTypeId: subVenue.typeId!,
      },
      skip: !subVenue.typeId,
    });

  const { loading: loadingVenueSetups, data: venueSetupsData } =
    useVenueSetupsByVenueDetailIdQuery({
      variables: {
        venueDetailId: parentVenue?.venueById.venueDetailId!,
      },
      skip: !parentVenue,
    });

  useEffect(() => {
    dispatch(getVenueTypes(""));
  }, []);

  async function SubmitSubVenueForm() {
    const result = SubVenueSchema.safeParse(subVenue);
    if (!result.success) {
      setZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is an issue with the form"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    const { id, ...otherFields } = subVenue;
    if (!parentId) {
      dispatch(displayAlertError("Parent venue missing"));
      return;
    }
    if (id !== undefined) {
      UpdateVenue({
        variables: {
          subVenueUpdateInput: {
            id,
            subVenueInput: {
              ...otherFields,
              parentId: parentId,
            },
          },
        },
        onCompleted: (data) => {
          if (data.subVenueUpdate.success) {
            dispatch(displayAlertSuccess(data.subVenueUpdate.message));
          } else {
            dispatch(displayAlertError(data.subVenueUpdate.message));
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    } else {
      CreateVenue({
        variables: {
          subVenueCreateInput: {
            ...otherFields,
            parentId: parentId,
          },
        },
        onCompleted: (data) => {
          if (data.subVenueCreate.success) {
            dispatch(displayAlertSuccess(data.subVenueCreate.message));
            setOpenPrePopulateDialog({
              open: true,
              newVenueId: data.subVenueCreate.venue?.id,
            });
          } else {
            dispatch(displayAlertError(data.subVenueCreate.message));
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    }
    setZodErrors({ _errors: [] });
  }

  const pathsBreadcrumbs = [
    { name: "Venue", url: "/ops/venues" },
    { name: "Venue Details", url: "/ops/venues" },
  ];

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/venues"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>
          {isEditVenue ? "Edit Sub-Venue" : `Create Sub-Venue`}
        </Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={SubmitSubVenueForm}
          >
            {isEditVenue ? "Edit Venue" : "Create Venue"}
          </Button>
        </div>
      </div>
      <div className="grid w-2/3 grid-cols-1 gap-2 mt-4">
        {renderZodErrors(zodErrors)}
      </div>
      <Card className="mt-6">
        <Subtitle1>Venue Details</Subtitle1>
        {parentVenue ? (
          <div className="flex flex-col">
            <div className="flex flex-row gap-4 mt-6">
              <FormFieldViewOnly
                text={parentVenue.venueById.name}
                name="parentId"
                label="Parent Venue"
                placeholder="Parent Venue"
                error={zodErrors.parentId !== undefined}
                assistiveText={
                  zodErrors.parentId !== undefined
                    ? zodErrors.parentId._errors[0]
                    : ""
                }
              />
            </div>
            <div className="flex flex-row w-full gap-4 mt-6">
              <FormField
                initialValue={subVenue.name ? subVenue.name : ""}
                inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSubVenue((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
                error={zodErrors.name !== undefined}
                assistiveText={
                  zodErrors.name !== undefined ? zodErrors.name._errors[0] : ""
                }
                name="name"
                label="Venue Name"
              ></FormField>
              <FormFieldSelect
                value={subVenue.typeId.toString()}
                inputChange={(value) => {
                  setSubVenue((prevState) => ({
                    ...prevState,
                    typeId: +value,
                    sizeId: 0,
                  }));
                }}
                error={zodErrors.typeId !== undefined}
                assistiveText={
                  zodErrors.typeId !== undefined
                    ? zodErrors.typeId._errors[0]
                    : ""
                }
                name="venueType"
                label="Venue Type"
                placeholder="Venue Type"
              >
                {[{ id: 0, name: "Select Venue Type" }, ...venueTypes]}
              </FormFieldSelect>
              <FormFieldSelect
                value={subVenue.sizeId.toString()}
                loading={loadingVenueSizes}
                inputChange={(value) => {
                  setSubVenue((prevState) => ({
                    ...prevState,
                    sizeId: parseInt(value),
                  }));
                }}
                error={zodErrors.sizeId !== undefined}
                assistiveText={
                  zodErrors.sizeId !== undefined
                    ? zodErrors.sizeId._errors[0]
                    : ""
                }
                name="venueSize"
                label="Venue Size"
                placeholder="Venue Size"
              >
                {[
                  ...[
                    {
                      id: 0,
                      name:
                        venueSizeData === undefined ||
                        venueSizeData.venueSizeByTypeId.length === 0
                          ? "Select Venue Type First"
                          : "Select Venue Size",
                    },
                  ],
                  ...(venueSizeData !== undefined
                    ? venueSizeData.venueSizeByTypeId.map((venueSize) => {
                        return {
                          id: venueSize.id,
                          name: venueSize.name,
                        };
                      })
                    : []),
                ]}
              </FormFieldSelect>
            </div>
            <div className="flex flex-row w-full gap-4 mt-6">
              <FormFieldSelect
                value={subVenue.islights ? "1" : "0"}
                inputChange={(value) => {
                  setSubVenue((prevState) => ({
                    ...prevState,
                    islights: value === "1" ? true : false,
                  }));
                }}
                name="islights"
                label="Lights"
                placeholder="Lights"
              >
                {[
                  { id: 0, name: "No" },
                  { id: 1, name: "Yes" },
                ]}
              </FormFieldSelect>
              <FormFieldSelect
                value={
                  subVenue.venueSetupId ? subVenue.venueSetupId.toString() : "0"
                }
                loading={loadingVenueSetups}
                inputChange={(value) => {
                  setSubVenue((prevState) => {
                    return {
                      ...prevState,
                      venueSetupId: value === "0" ? undefined : +value,
                    };
                  });
                }}
                name="venueSetup"
                label="Venue Setup"
                placeholder="Venue Setup"
              >
                {[
                  { id: 0, name: "None" },
                  ...(venueSetupsData
                    ? venueSetupsData.venueSetupsByVenueDetailId
                    : []),
                ]}
              </FormFieldSelect>
              <FormField
                initialValue={
                  subVenue.startBuffer ? subVenue.startBuffer.toString() : "0"
                }
                inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSubVenue((prevState) => ({
                    ...prevState,
                    startBuffer: +e.target.value,
                  }));
                }}
                name="startBuffer"
                label="Start Buffer"
              ></FormField>
              <FormField
                initialValue={
                  subVenue.endBuffer ? subVenue.endBuffer.toString() : "0"
                }
                inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSubVenue((prevState) => ({
                    ...prevState,
                    endBuffer: +e.target.value,
                  }));
                }}
                name="endBuffer"
                label="End Buffer"
              ></FormField>
            </div>
          </div>
        ) : (
          <Body1>Could not find parent venue</Body1>
        )}
      </Card>
      <div className="flex justify-end mt-6">
        <Button
          variant="primary"
          onClick={SubmitSubVenueForm}
        >
          {isEditVenue ? "Edit Venue" : "Create Venue"}
        </Button>
      </div>
      <Dialog
        open={openPrePopulateDialog.open}
        onOpenChange={(open) => {
          setOpenPrePopulateDialog((prevState) => ({
            ...prevState,
            open,
          }));
        }}
      >
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>Pre-populate New Sub Venue</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col">
              <Body1>
                You can pre-populate a new sub-venue with either the same parent
                as the newly created one or using the newly created venue as the
                parent.
              </Body1>
            </div>
          </DialogDescription>
          <div className="flex flex-row gap-2">
            <DialogClose asChild>
              <Button
                className="w-full h-fit"
                variant="secondary"
                onClick={() => {
                  setSubVenue((prevState) => ({
                    ...prevState,
                    name: "",
                  }));
                }}
              >
                Same parent
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                className="w-full h-fit whitespace-nowrap"
                variant="secondary"
                onClick={() => {
                  setSubVenue((prevState) => ({
                    ...prevState,
                    name: "",
                  }));
                  if (openPrePopulateDialog.newVenueId) {
                    navigate(
                      `/ops/sub-venue-management/${openPrePopulateDialog.newVenueId}`
                    );
                  }
                }}
              >
                New venue as parent
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                className="w-full h-fit"
                variant="negative"
                onClick={() => {
                  navigate("/ops/venues");
                }}
              >
                Close
              </Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>
    </main>
  );
};

export default CreateSubVenue;
