import {
  GameInfoForGameslot,
  GameslotGameConflictRelational,
  GameslotGameConflictState,
} from "@/src/generated/graphql";
import Body1 from "../../UI/Text/Body/Body1";
import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  GroupWorkOutlined,
  RuleOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import Caption1 from "../../UI/Text/Caption/Caption1";
export const gameslotGameCheckTypeToIcon = (type: number) => {
  if (type === 1) {
    return (
      <CheckCircleOutlineOutlined
        className="transition-colors text-success-50"
        fontSize="small"
      />
    );
  } else if (type === 5) {
    return (
      <ErrorOutlineOutlined
        className="transition-colors text-error-50"
        fontSize="small"
      />
    );
  } else if (type === 4) {
    return (
      <WarningAmberOutlined
        className="transition-colors text-warning-50"
        fontSize="small"
      />
    );
  } else if (type === 6) {
    return (
      <GroupWorkOutlined
        className="transition-colors text-warning-50"
        fontSize="small"
      />
    );
  } else if (type === 3) {
    return (
      <RuleOutlined
        className="transition-colors text-warning-50"
        fontSize="small"
      />
    );
  }
};

export const GameSlotCheckUi = (
  gameslotGameCheck: Pick<GameslotGameConflictRelational, "gameslotStates">
) => {
  const gameslotStateGameInfoMap = (
    gameslotState: GameslotGameConflictState
  ) => {
    return gameslotState.gamesInfo.reduce(
      (acc: Map<number, GameInfoForGameslot[]>, cur) => {
        const gamesForDiv = acc.get(cur.divisionId);
        if (gamesForDiv === undefined) {
          acc.set(cur.divisionId, [cur]);
        } else {
          acc.set(cur.divisionId, [...gamesForDiv, cur]);
        }
        return acc;
      },
      new Map<number, GameInfoForGameslot[]>()
    );
  };

  return (
    <>
      {gameslotGameCheck.gameslotStates.map((gameslotState) => {
        return (
          <div className="flex flex-col gap-1">
            <Body1>
              <div className="flex flex-row items-center gap-2">
                {gameslotGameCheckTypeToIcon(gameslotState.type)}
                {gameslotState.message}
              </div>
            </Body1>
            {Array.from(gameslotStateGameInfoMap(gameslotState).entries()).map(
              ([divisionId, gamesInfo]) => (
                <div className="flex flex-col">
                  <Body1>
                    <a
                      href={`/ops/division/${divisionId}/schedule`}
                      className="px-2 py-1 transition-colors rounded-sm hover:bg-neutral-70"
                    >
                      Division: {gamesInfo[0].divisionName}
                    </a>
                  </Body1>
                  <Caption1 className="px-2">
                    Game Ids:{" "}
                    {gamesInfo
                      .map((game) => {
                        return game.gameId;
                      })
                      .join(", ")}
                  </Caption1>
                </div>
              )
            )}
          </div>
        );
      })}
    </>
  );
};
