import { gql } from "@apollo/client";

const GET_SHOPPING_CARTS = gql`
  query ShoppingCarts(
    $shoppingCartFilters: ShoppingCartFilters
    $page: Int
    $pageSize: Int
  ) {
    shoppingCarts(
      shoppingCartFilters: $shoppingCartFilters
      page: $page
      pageSize: $pageSize
    ) {
      shoppingCarts {
        id
        lastActive
        createdAt
        user {
          id
          firstName
          lastName
        }
        shoppingCartStatus {
          id
          name
        }
        shoppingCartItems {
          id
          createdAt
          itemAmount
          amountPaid
          discount
          tax
          txnFee
          shoppingCartTransactions {
            id
            transactionAmount
            transactionType {
              id
              name
            }
            success
          }
        }
        shoppingCartTransactions {
          id
          transactionAmount
          transactionType {
            id
            name
          }
          success
        }
      }
      count
    }
  }
`;

const GET_SHOPPING_CART_BY_ID = gql`
  query ShoppingCart($shoppingCartId: Int!) {
    shoppingCart(id: $shoppingCartId) {
      id
      lastActive
      createdAt
      shoppingCartStatus {
        name
      }
      user {
        id
        email
        lastName
        firstName
      }
      shoppingCartItems {
        createdAt
        id
        amountPaid
        itemAmount
        productTypeId
        discount
        tax
        txnFee
        promoCode {
          id
          name
        }
        session {
          id
          dayOfWeek
          registrationBatch {
            name
          }
          league {
            name
            sportFormat {
              region {
                id
                name
                currency
              }
            }
          }
        }
        tournament {
          id
          dayOfWeek
          name
          sportFormat {
            region {
              id
              name
              currency
            }
          }
        }
        productType {
          id
          name
        }
        shoppingCartTransactions {
          id
          transactionAmount
          transactionType {
            id
            name
          }
          success
          createdAt
        }
        registration {
          isDeleted
        }
        leagueTransferKey
        leagueTransferIdOut
        refunds {
          id
          amount
          status {
            id
            name
          }
          type {
            id
            name
          }
          reason {
            id
            name
          }
          requestedDatetime
          requestedBy {
            id
            email
            firstName
            lastName
          }
          reviewedByDateTime
          reviewedBy {
            firstName
            lastName
          }
          txnId
          notes
        }
      }
      shoppingCartTransactions {
        id
        transactionAmount
        transactionType {
          id
          name
        }
        success
        createdAt
        gatewayMessage
        shoppingCartItems {
          id
        }
        orderId
      }
    }
  }
`;

const GET_SHOPPING_CART_BY_USERID = gql`
  query GetShoppingCartByUserId($userId: String!) {
    getShoppingCartByUserId(userId: $userId) {
      id
      lastActive
      createdAt
      user {
        id
        email
        lastName
        firstName
      }
      shoppingCartItems {
        createdAt
        id
        amountPaid
        itemAmount
        discount
        tax
        session {
          id
          league {
            name
          }
        }
        tournament {
          id
          name
        }
        productType {
          id
          name
        }
        discountType {
          id
          name
        }
        shoppingCartTransactions {
          id
          transactionAmount
          transactionType {
            id
            name
          }
          success
        }
      }
    }
  }
`;
const GET_ALL_SHOPPING_CARTS_BY_USERID = gql`
  query GetAllShoppingCartsByUserId($userId: String!) {
    getAllShoppingCartsByUserId(userId: $userId) {
      id
      lastActive
      createdAt
      shoppingCartStatus {
        id
        name
      }
      shoppingCartTransactions {
        id
        transactionAmount
        transactionType {
          id
          name
        }
        success
      }
    }
  }
`;

const CREATE_REFUND = gql`
  mutation Refund(
    $refundArgs: CreateRefundInput!
    $removeRegistration: Boolean!
  ) {
    refund(refundArgs: $refundArgs, removeRegistration: $removeRegistration) {
      code
      message
      success
      refund {
        id
      }
    }
  }
`;

const CREATE_PURCHASE_CORRECTION = gql`
  mutation PurchaseCorrection($transactionId: Int!) {
    purchaseCorrection(transactionId: $transactionId) {
      code
      message
      success
      shoppingCartTransaction {
        id
      }
    }
  }
`;

const CREATE_SHOPPING_CART = gql`
  mutation ShoppingCartCreate($shoppingCartArgs: CreateShoppingCartInput!) {
    shoppingCartCreate(shoppingCartArgs: $shoppingCartArgs) {
      code
      message
      success
    }
  }
`;

const SHOPPING_CART_ITEM_ADD = gql`
  mutation ShoppingCartItemAdd(
    $shoppingCartItemArgs: AddShoppingCartItemInput!
  ) {
    shoppingCartItemAdd(shoppingCartItemArgs: $shoppingCartItemArgs) {
      success
      message
      code
    }
  }
`;

const SHOPPING_CART_ITEM_REMOVE = gql`
  mutation ShoppingCartItemRemove(
    $shoppingCartId: Int!
    $shoppingCartItemId: Int!
    $userId: String!
  ) {
    shoppingCartItemRemove(
      shoppingCartId: $shoppingCartId
      shoppingCartItemId: $shoppingCartItemId
      userId: $userId
    ) {
      code
      message
      success
    }
  }
`;

const SHOPPING_CART_ITEM_REMOVE_LEAGUE_TRANSFER = gql`
  mutation ShoppingCartItemRemoveLeagueTransfer(
    $shoppingCartId: Int!
    $shoppingCartItemId: Int!
  ) {
    shoppingCartItemRemoveLeagueTransfer(
      shoppingCartId: $shoppingCartId
      shoppingCartItemId: $shoppingCartItemId
    ) {
      code
      message
      success
    }
  }
`;

const CREATE_LEAGUE_TRANSFER = gql`
  mutation LeagueTransfer(
    $userId: String!
    $shoppingCartItemId: Int!
    $newSessionId: Int!
    $overwriteTransfer: Boolean!
    $refundArgs: CreateRefundLeagueTransferInput
    $overwriteRefund: Boolean!
  ) {
    leagueTransfer(
      userId: $userId
      shoppingCartItemId: $shoppingCartItemId
      newSessionId: $newSessionId
      overwriteTransfer: $overwriteTransfer
      refundArgs: $refundArgs
      overwriteRefund: $overwriteRefund
    ) {
      code
      message
      success
      leagueTransferURL
    }
  }
`;

const REMOVE_REGISTRATION = gql`
  mutation RemoveRegistration($shoppingCartItemId: Int!, $productTypeId: Int!) {
    removeRegistration(
      shoppingCartItemId: $shoppingCartItemId
      productTypeId: $productTypeId
    ) {
      code
      message
      success
    }
  }
`;

gql`
  mutation CreditPurchase($creditPurchaseArgs: CreditPurchaseInput!) {
    creditPurchase(creditPurchaseArgs: $creditPurchaseArgs) {
      code
      message
      success
    }
  }
`;

export {
  GET_SHOPPING_CARTS,
  GET_SHOPPING_CART_BY_ID,
  CREATE_REFUND,
  CREATE_PURCHASE_CORRECTION,
  GET_SHOPPING_CART_BY_USERID,
  GET_ALL_SHOPPING_CARTS_BY_USERID,
  CREATE_SHOPPING_CART,
  SHOPPING_CART_ITEM_ADD,
  SHOPPING_CART_ITEM_REMOVE,
  CREATE_LEAGUE_TRANSFER,
  SHOPPING_CART_ITEM_REMOVE_LEAGUE_TRANSFER,
  REMOVE_REGISTRATION,
};
