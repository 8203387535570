import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import {
  useSessionForSessionHqQuery,
  useSessionQuery,
  useSessionsQuery,
} from "../../../../generated/graphql";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { getSports } from "../../../../app/venueMasterSlice";
import {
  FormFieldSelectMultiSearch,
  MultiSelectRef,
} from "../../../../components/UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";
import { dayOfWeek } from "../../../../utils/dayOfWeek";
import { useSessionWorkflowContext } from "../../../../context/SessionWorkflowContext";
import Headline2Variable from "../../../../components/UI/Text/Headline/Headline2Variable";
import Card from "../../../../components/UI/Card/Card";
import Subtitle1 from "../../../../components/UI/Text/Subtitle/Subtitle1";
import TabGroup from "../../../../components/UI/Tab/TabGroup/TabGroup";
import SessionWorkflowRegistration from "./SessionWorkflowRegistration";
import SessionWorkflowWaitLists from "./SessionWorkflowWaitlist";
import SessionWorkflowIncompleteCart from "./SessionWorkflowIncompleteCart";
import { cn } from "../../../../lib/utils";
import { genderIdentityIdClassify } from "../../../../utils/genderIdentity";
import Button from "../../../../components/UI/Button/Button";

const SessionWorkflow: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    filter,
    setFilter,
    selectedSession,
    setSelectedSession,
    tabGroupValue,
    setTabGroupValue,
  } = useSessionWorkflowContext();
  const params = useParams();
  // The id will be used to know if we're editing or creating data
  const { id } = params;
  // The id should be "Create" if we're creating data so this will return false
  const sessionId = id !== undefined && !isNaN(+id) ? +id : undefined;

  const { selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  /*** REFERENCES ***/
  const multiSelectRef = React.useRef<MultiSelectRef>(null);

  const handleClear = () => {
    multiSelectRef.current?.clearState();
  };

  /*** STATES ***/

  // Basic filter add new id types here for more filtering

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const { loading: loadingSessions, data: dataSessions } =
    useSessionForSessionHqQuery({
      variables: {
        sessionId: +sessionId!,
      },
      skip: !sessionId,
      onCompleted: (data) => {
        setSelectedSession(data.session);
      },
    });

  // /*** USE EFFECTS ***/
  // useEffect(() => {
  //   if (multiSelectRef.current) {
  //     console.log("clear");

  //     handleClear();
  //   }
  // }, [filter.regionId, filter.sportId]);

  // // Get any redux data needed for filters here
  // useEffect(() => {
  //   dispatch(getSports(""));
  // }, [dispatch]);

  /*** UTILITY FUNCTIONS ***/
  if (!selectedSession) {
    return <>No Session</>;
  }

  return (
    <main className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between w-full">
        <Headline1Variable>Session HQ</Headline1Variable>
        <Button
          variant="secondary"
          className="h-fit"
          href={`/marketing/email-tool/session/${sessionId}`}
        >
          Session Email Tool
        </Button>
      </div>
      {/* Sessoin Details */}
      <div className="flex flex-col gap-2">
        <Headline2Variable>Session Info</Headline2Variable>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row flex-wrap justify-start gap-8">
            <Card className="w-min">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">
                  Session Details
                </Subtitle1>
                <Subtitle1 className="whitespace-nowrap">
                  <a
                    className="underline cursor-pointer text-info-50 hover:text-info-30 whitespace-nowrap"
                    href={`/ops/session/${selectedSession.id}`}
                  >
                    Id: {selectedSession ? `${selectedSession.id}` : "N/A"}
                  </a>
                </Subtitle1>
              </div>
            </Card>
            <Card className="w-min">
              <div className="flex flex-col items-start justify-between">
                <Subtitle1 className="whitespace-nowrap">League Name</Subtitle1>
                <Subtitle1 className="whitespace-nowrap">
                  <a
                    className="underline cursor-pointer text-info-50 hover:text-info-30 whitespace-nowrap"
                    href={`/ops/league/${selectedSession.league.id}`}
                  >
                    {selectedSession.league.name}
                  </a>
                </Subtitle1>
              </div>
            </Card>
            <Card className="w-min">
              <div className="flex flex-col items-start justify-between">
                <Subtitle1 className="whitespace-nowrap">
                  Sport Format
                </Subtitle1>
                <Subtitle1 className="whitespace-nowrap">
                  <a
                    className="underline cursor-pointer text-info-50 hover:text-info-30 whitespace-nowrap"
                    href={`/ops/sport-format/${selectedSession.league.sportFormat.id}`}
                  >
                    {selectedSession.league.sportFormat.name}
                  </a>
                </Subtitle1>
              </div>
            </Card>
            <Card className="w-min">
              <div className="flex flex-col items-start justify-between">
                {selectedSession.capacityGroup ? (
                  <Subtitle1 className="whitespace-nowrap">
                    <a
                      className="underline cursor-pointer text-info-50 hover:text-info-30 whitespace-nowrap"
                      href={`/ops/capacity-group/${selectedSession.capacityGroup.id}`}
                    >
                      Capacity Group
                    </a>
                  </Subtitle1>
                ) : (
                  <Subtitle1 className="whitespace-nowrap">
                    No Capacity Group
                  </Subtitle1>
                )}
              </div>
            </Card>
            <Card className="w-fit">
              <Subtitle1 className="whitespace-nowrap">Divisions</Subtitle1>
              <div className="grid grid-cols-2">
                {selectedSession
                  ? selectedSession.divisions.map((division) => {
                      return (
                        <a
                          className="underline cursor-pointer text-info-50 hover:text-info-30 whitespace-nowrap"
                          href={`/ops/division/${division.id}`}
                        >
                          {division.name}
                        </a>
                      );
                    })
                  : "N/A"}
              </div>
            </Card>
          </div>
        </div>
      </div>
      {/* Capacity */}
      <div className="flex flex-col gap-2">
        <Headline2Variable>Capacity</Headline2Variable>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-start gap-8">
            <Card className="w-min">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">
                  Max Capacity
                </Subtitle1>
                <Headline2Variable>
                  {selectedSession.maxCapacity}
                </Headline2Variable>
              </div>
            </Card>
            <Card className="w-min">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">
                  Set Capacity
                </Subtitle1>
                <Headline2Variable>
                  {selectedSession.setCapacity}
                </Headline2Variable>
              </div>
            </Card>
            <Card className="w-min">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">
                  FA Team Capacity
                </Subtitle1>
                <Headline2Variable>
                  {selectedSession.indyTeams}
                </Headline2Variable>
              </div>
            </Card>
          </div>
        </div>
      </div>
      {/* Registrations */}
      <div className="flex flex-col gap-2">
        <Headline2Variable>Registrations</Headline2Variable>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-start gap-8">
            <Card className="w-min">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">Teams</Subtitle1>
                <Headline2Variable>
                  {selectedSession
                    ? `${selectedSession.teamsRegistered}/${
                        selectedSession.setCapacity - selectedSession.indyTeams
                      }`
                    : "N/A"}
                </Headline2Variable>
              </div>
            </Card>
            <Card className="w-fit">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">Free Agents</Subtitle1>
                <div className="flex flex-row gap-2">
                  <Headline2Variable>
                    M{selectedSession.freeAgentCount?.men}
                  </Headline2Variable>
                  <Headline2Variable>
                    W{selectedSession.freeAgentCount?.women}
                  </Headline2Variable>
                  <Headline2Variable>
                    X{selectedSession.freeAgentCount?.other}
                  </Headline2Variable>
                </div>
              </div>
            </Card>
            <Card className="w-min">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">
                  <a
                    className={cn(
                      ` whitespace-nowrap ${
                        selectedSession &&
                        "underline cursor-pointer text-info-50 hover:text-info-30"
                      }`
                    )}
                    href={`/ops/free-agent-teams?sessionId=${selectedSession?.id}`}
                  >
                    Free Agent Teams
                  </a>
                </Subtitle1>
                <Headline2Variable>
                  {selectedSession
                    ? `${
                        selectedSession.teams.filter(
                          (team) => team.isIndyTeam === true
                        ).length
                      }/${selectedSession.indyTeams}`
                    : "N/A"}
                </Headline2Variable>
              </div>
            </Card>
          </div>
        </div>
      </div>
      {/* Waitlists */}
      <div className="flex flex-col gap-2">
        <Headline2Variable>Wait List</Headline2Variable>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-start gap-8">
            <Card className="w-min">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">Teams</Subtitle1>
                <Headline2Variable>
                  {
                    selectedSession.waitList.filter(
                      (waitList) => waitList.productTypeId === 2
                    ).length
                  }
                </Headline2Variable>
              </div>
            </Card>
            <Card className="w-fit">
              <div className="flex flex-col items-center justify-between">
                <Subtitle1 className="whitespace-nowrap">Free Agents</Subtitle1>
                <div className="flex flex-row gap-2">
                  <Headline2Variable>
                    M
                    {
                      selectedSession.waitList.filter(
                        (waitList) =>
                          waitList.user.genderIdentityId &&
                          waitList.productTypeId === 1 &&
                          genderIdentityIdClassify(
                            waitList.user.genderIdentityId
                          ) === 1
                      ).length
                    }
                  </Headline2Variable>
                  <Headline2Variable>
                    W
                    {
                      selectedSession.waitList.filter(
                        (waitList) =>
                          waitList.user.genderIdentityId &&
                          waitList.productTypeId === 1 &&
                          genderIdentityIdClassify(
                            waitList.user.genderIdentityId
                          ) === 2
                      ).length
                    }
                  </Headline2Variable>
                  <Headline2Variable>
                    X
                    {
                      selectedSession.waitList.filter(
                        (waitList) =>
                          waitList.user.genderIdentityId &&
                          waitList.productTypeId === 1 &&
                          genderIdentityIdClassify(
                            waitList.user.genderIdentityId
                          ) === 3
                      ).length
                    }
                  </Headline2Variable>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 pb-10">
        <Headline2Variable>Session Reports</Headline2Variable>
        <TabGroup
          content={["Registrations", "Waitlist", "Incomplete Carts"]}
          inputChange={(value) => {
            setTabGroupValue(value);
          }}
          value={tabGroupValue}
          className="max-w-full w-fit"
        />
        {tabGroupValue === "Registrations" ? (
          <SessionWorkflowRegistration />
        ) : tabGroupValue === "Waitlist" ? (
          <SessionWorkflowWaitLists />
        ) : (
          <SessionWorkflowIncompleteCart />
        )}
      </div>
    </main>
  );
};

export default SessionWorkflow;
