import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AllShifts,
  AllContractorShiftsReport,
  Shift,
  RequestedShift,
  ShiftNotes,
} from "./components/PTS/Administrator";
import {
  OpenShifts,
  MyShifts,
  AppliedShifts,
} from "./components/PTS/Ambassdor";
import {
  Vendors,
  ContractManagement,
  CreateVenue,
  Venues,
  ShoppingCart,
  ShoppingCarts,
  LegalDocumentManagement,
} from "./components/Admin";
import SportFormats from "./components/Admin/SportFormats";
import CreateSportFormat from "./components/Admin/CreateSportFormat";
import CreateLeague from "./components/Admin/CreateLeague";
import Leagues from "./components/Admin/Leagues";
import Seasons from "./components/Admin/Seasons";
import CreateSeason from "./components/Admin/CreateSeason";
import CreateSession from "./components/Admin/CreateSession";
import Sessions from "./components/Admin/Sessions";
import Divisions from "./components/Admin/Divisions";
import CreateDivision from "./components/Admin/Division/CreateDivision";

import { Home, Error, Register } from "./pages";

import CreateIndyTeam from "./components/Admin/CreateIndyTeam";
import PermissionDenied from "./pages/PermissionDenied";
import Base from "./components/Home/Base";
import VenueHome from "./components/Admin/HomePages/OperationVenueHome";
import LeagueHome from "./components/Admin/HomePages/OperationLeagueHome";
import ScheduleHome from "./components/Admin/HomePages/OperationScheduleHome";
import PlayerReportHome from "./components/Admin/HomePages/PlayerReportHome";
import EventHome from "./components/Admin/HomePages/OperationEventHome";
import PlayerSearchHome from "./components/Admin/HomePages/PlayerSearchHome";
import StaffingStaffHome from "./components/Admin/HomePages/StaffingStaffHome";
import StaffingShiftsHome from "./components/Admin/HomePages/StaffingShiftsHome";
import StaffingReportHome from "./components/Admin/HomePages/StaffingReportHome";
import MarketingContentManagementHome from "./components/Admin/HomePages/MarketingContentManagementHome";
import MarketingMailingListHome from "./components/Admin/HomePages/MarketingMailingListHome";
import FinanceTransactionsHome from "./components/Admin/HomePages/FinanceTransactionsHome";
import FinanceCreditsHome from "./components/Admin/HomePages/FinanceCreditsHome";
import FinancePromoCodesHome from "./components/Admin/HomePages/FinancePromoCodesHome";
import Content from "./components/Marketing/Content";
import Rules from "./components/Admin/Rules";
import CreateVendor from "./components/Admin/CreateVendor";
import CreateSubVenue from "./components/Admin/CreateSubVenue";
import CreateShoppingCart from "./components/Admin/CreateShoppingCart";
import TestPage from "./components/Home/TestPage";
import Players from "./components/Players/Players";
import Player from "./components/Players/Player";
import Registrations from "./components/Admin/Registrations";
import EmailTool from "./components/Marketing/Email/EmailTool";
import Wayfinder from "./components/Admin/LeagueWayfinder";
import Permissions from "./components/Players/Permissions";
import AuthorizedRoutes from "./pages/AuthorizedRoutes";
import { EPermission } from "./utils/permissions";
import EmailToolDivisions from "./components/Marketing/Email/EmailToolDivisions";
import JAIms from "./components/Admin/JAIms";
import EmailTemplateManager from "./components/Marketing/Email/EmailTemplateManager";
import GameReport from "./components/Admin/GameReport";
import RefundApproval from "./components/Admin/RefundApprovalReport";
import PayrollBatches from "./components/PTS/Administrator/PayrollBatches";
import CreatePayrollBatches from "./components/PTS/Administrator/CreatePayrollBatches";
import PayrollBatch from "./components/PTS/Administrator/PayrollBatch";
import ShiftsForReview from "./components/PTS/Administrator/ShiftsForReview";
import ShiftsForPayroll from "./components/PTS/Administrator/ShiftsForPayroll";
import EmailToolMarketing from "./components/Marketing/Email/EmailToolMarketing";
import EmailToolMarketingFilter from "./components/Marketing/Email/EmailToolMarketingFilter";
import CreateContract from "./components/Admin/Contract/CreateContractNew";
import ScheduleTest from "./components/Admin/Schedule/ScheduleTest";
import ScheduleNew from "./components/Admin/Schedule/ScheduleNew";
import ReseedDivisions from "./components/Admin/ReseedDivisions";
import EmailMarketingReport from "./components/Marketing/EmailMarketingReport";
import EmailToolMarketingSend from "./components/Marketing/Email/EmailToolMarketingSend";
import WaitList from "./components/Players/WaitList";
import RulesReport from "./components/Admin/RulesReport";
import ImageManager from "./components/Admin/ImageManager";
import GameScoreReport from "./components/Admin/GameScoreReport";
import StaffTypeManagement from "./components/PTS/Administrator/StaffTypeManagement/StaffTypeManagement";
import StaffTypeManagementReport from "./components/PTS/Administrator/StaffTypeManagement/StaffTypeManagementReport";
import { StaffTypeContextProvider } from "./context/StaffTypeContext";
import ThirdPartyContractorReport from "./components/PTS/Administrator/ThirdPartyContractor/ThirdPartyContractorReport";
import ThirdPartyContractor from "./components/PTS/Administrator/ThirdPartyContractor/ThirdPartyContractor";
import CapacityGroupOverview from "./components/Admin/CapacityGroup/CapacityGroupOverview";
import CapacityGroup from "./components/Admin/CapacityGroup/CapacityGroup";
import VenueOverview from "./components/Admin/VenueOverview/VenueOverview";
import BugReport from "./components/Dev/BugReport";
import FeatureReport from "./components/Dev/FeatureReport";
import { PageTitleContextProvider } from "./context/PageTitleContext";
import Weather from "./components/Admin/Weather/Weather";
import LogoutUser from "./pages/LogoutUser";
import EditContract from "./components/Admin/Contract/EditContract";
import SessionWorkflow from "./components/Admin/Session/SessionWorkflow/SessionWorkflow";
import EmailToolSessions from "./components/Marketing/Email/EmailToolSessions";
import Team from "./components/Players/TeamHq";
import GameSummary from "./components/Admin/GameSummary";
import TournamentReport from "./components/Admin/Tournament/TournamentReport";
import TournamentManagement from "./components/Admin/Tournament/TournamentManagement";
import CreditTransfer from "./components/Admin/CreditTransferReport";
import PromoCodes from "./components/Admin/PromoCode/PromoCodeReport";
import CreatePromoCode from "./components/Admin/PromoCode/PromoCodeManagement";
import ProtectedRoute from "./pages/ProtectedRoutes";
import SessionFreeAgentBreakdown from "./components/Admin/Session/SessionFreeAgentBreakdown/SessionFreeAgentBreakdown";
import RegOpening from "./components/RegOpening/RegOpening";

function App() {
  return (
    <BrowserRouter>
      <PageTitleContextProvider>
        <Routes>
          <Route
            path={`/login`}
            element={<Register />}
          />

          <Route
            path={`/`}
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          >
            {/* HOME PAGES */}
            {/* Operations */}
            <Route
              path={`/ops/venue`}
              element={<VenueHome />}
            />
            <Route
              path={`/ops/schedule`}
              element={<ScheduleHome />}
            />
            <Route
              path={`/ops/event`}
              element={<EventHome />}
            />
            <Route
              path={`/ops/game-scores`}
              element={<GameScoreReport />}
            />
            <Route
              path={`/ops/game-report`}
              element={<GameReport />}
            />
            {/* PLAYERS */}
            <Route
              path={`/players/reports`}
              element={<PlayerReportHome />}
            />
            <Route
              path={`/players/search`}
              element={<PlayerSearchHome />}
            />
            <Route
              path={`/players/users`}
              element={<Players />}
            />
            <Route
              path={`/players/user/:id`}
              element={<Player />}
            />
            <Route
              path={`/players/team/:id`}
              element={<Team />}
            />
            <Route
              path={`/players/permissions`}
              element={<Permissions />}
            />
            {/* STAFFING */}
            <Route
              path={`/staffing/staff`}
              element={<StaffingStaffHome />}
            />
            <Route
              path={`/staffing/shifts`}
              element={<StaffingShiftsHome />}
            />
            <Route
              path={`/staffing/reports`}
              element={<StaffingReportHome />}
            />
            {/* MARKETING */}
            <Route
              path={`/marketing/content-management`}
              element={<MarketingContentManagementHome />}
            />
            <Route
              path={`/marketing/email-tool/division/:id?`}
              element={<EmailToolDivisions />}
            />
            <Route
              path={`/marketing/email-tool/session/:id?`}
              element={<EmailToolSessions />}
            />
            <Route
              path={`/marketing/email-tool/marketing/:id?`}
              element={<EmailToolMarketing />}
            />
            <Route
              path={`/marketing/email-tool/marketing/report`}
              element={<EmailMarketingReport />}
            />
            <Route
              path={`/marketing/email-tool/marketing/filter/:id?`}
              element={<EmailToolMarketingFilter />}
            />
            <Route
              path={`/marketing/email-tool/marketing/send/:id`}
              element={<EmailToolMarketingSend />}
            />
            <Route
              path={`/marketing/email-tool/email-template-manager`}
              element={<EmailTemplateManager />}
            />
            <Route
              path={`/marketing/email-tool`}
              element={<EmailTool />}
            />
            <Route
              path={`/marketing/content`}
              element={<Content />}
            />
            <Route
              path={`/marketing/mailing-list`}
              element={<MarketingMailingListHome />}
            />
            <Route
              path={`/marketing/jAIms`}
              element={<JAIms />}
            />
            <Route
              path={`/marketing/image-manager`}
              element={<ImageManager />}
            />
            <Route
              path={`/marketing/promo-codes`}
              element={<PromoCodes />}
            />
            <Route
              path={`/marketing/promo-codes/new`}
              element={<CreatePromoCode />}
            />
            {/* FINANCE */}
            <Route
              path={`/finance/transactions`}
              element={<FinanceTransactionsHome />}
            />
            <Route
              path={`/finance/credits`}
              element={<FinanceCreditsHome />}
            />
            <Route
              path={`/finance/promo-codes`}
              element={<FinancePromoCodesHome />}
            />
            <Route
              path={`/finance/refund-approvals`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["REFUND_APPROVAL"]}
                >
                  <RefundApproval />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/finance/credit-transfer`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["REFUND_APPROVAL"]}
                >
                  <CreditTransfer />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/finance/payrollBatches`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["FINANCE_PAYROLL"]}
                >
                  <PayrollBatches />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/finance/createPayrollBatch`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["FINANCE_PAYROLL"]}
                >
                  <CreatePayrollBatches />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/finance/createPayrollBatch/:id`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["FINANCE_PAYROLL"]}
                >
                  <CreatePayrollBatches />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/finance/shiftsForReview`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["CREATE_SHIFT"]}
                >
                  <ShiftsForReview />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/finance/shiftsForPayroll`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["FINANCE_PAYROLL"]}
                >
                  <ShiftsForPayroll />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/finance/payrollBatch/:id`}
              element={<PayrollBatch />}
            />
            {/* OPS */}
            <Route
              path={`/ops/contracts`}
              element={<ContractManagement />}
            />
            <Route
              path={`/weather`}
              element={<Weather />}
            />
            {/* create initial state as prop here */}
            <Route
              path={`/ops/contract/new`}
              element={<CreateContract />}
            />
            <Route
              path={`/ops/contract/:id`}
              element={<EditContract />}
            />
            {/* <Route
            path={`/create-venue`}
            element={
              
                <CreateVenue />
              
            }
          /> */}
            <Route
              path={`/ops/venue-overview`}
              element={<VenueOverview />}
            />
            <Route
              path={`/ops/venues`}
              element={<Venues />}
            />
            <Route
              path={`/ops/venue-management`}
              element={<CreateVenue />}
            />
            <Route
              path={`/ops/venue-management/:id`}
              element={<CreateVenue />}
            />
            <Route
              path={`/ops/sub-venue-management/:parentId`}
              element={<CreateSubVenue />}
            />
            <Route
              path={`/ops/sub-venue-management/:parentId/:id`}
              element={<CreateSubVenue />}
            />
            <Route
              path={`/ops/vendors`}
              element={<Vendors />}
            />
            <Route
              path={`/ops/vendor`}
              element={<CreateVendor />}
            />
            <Route
              path={`/ops/vendor/:id`}
              element={<CreateVendor />}
            />
            {/* DEV */}
            <Route
              path={`/dev/bug-report`}
              element={<BugReport />}
            />
            <Route
              path={`/dev/feature-report`}
              element={<FeatureReport />}
            />
            {/* PTS */}
            <Route
              path={`/staffing/shift/new`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["CREATE_SHIFT"]}
                >
                  <Shift requiredPermission={EPermission["CREATE_SHIFT"]} />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/staffing/shift/:id`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["EDIT_SHIFT"]}
                >
                  <Shift requiredPermission={EPermission["EDIT_SHIFT"]} />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/staffing/all-staff-shifts`}
              element={<AllShifts />}
            />
            <Route
              path={`/staffing/all-contractor-shifts`}
              element={<AllContractorShiftsReport />}
            />
            <Route
              path={`/staffing/requested-shifts`}
              element={<RequestedShift />}
            />
            <Route
              path={`/staffing/staff-type-management`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["MANAGE_SHIFT_STAFF"]}
                >
                  <StaffTypeManagementReport />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/staffing/staff-type-management/:id?`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["MANAGE_SHIFT_STAFF"]}
                >
                  <StaffTypeManagement />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/staffing/third-party-contractor-management`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["MANAGE_SHIFT_STAFF"]}
                >
                  <ThirdPartyContractorReport />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/staffing/third-party-contractor-management/:id?`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["MANAGE_SHIFT_STAFF"]}
                >
                  <ThirdPartyContractor />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/staffing/shift-notes/:id`}
              element={<ShiftNotes />}
            />
            <Route
              path={`/staffing/open-shifts`}
              element={<OpenShifts />}
            />
            <Route
              path={`/staffing/applied-shifts`}
              element={<AppliedShifts />}
            />
            {/* <Route
            path={`/staffing/shifts-finance`}
            element={
              
                <ShiftsFinance />
              
            }
          /> */}
            <Route
              path={`/ops/sport-format/new`}
              element={<CreateSportFormat />}
            />
            <Route
              path={`/ops/sport-format`}
              element={<SportFormats />}
            />
            <Route
              path={`/ops/sport-format/:id`}
              element={<CreateSportFormat />}
            />
            <Route
              path={`/ops/leagues`}
              element={<Leagues />}
            />
            <Route
              path={`/ops/league`}
              element={<CreateLeague />}
            />
            <Route
              path={`/ops/league/:id`}
              element={<CreateLeague />}
            />
            <Route
              path={`/ops/seasons`}
              element={<Seasons />}
            />
            <Route
              path={`/ops/rule/:id?`}
              element={<Rules />}
            />
            <Route
              path={`/ops/rules`}
              element={<RulesReport />}
            />
            <Route
              path={`/ops/season/:id`}
              element={<CreateSeason />}
            />
            <Route
              path={`/ops/season`}
              element={<CreateSeason />}
            />
            <Route
              path={`/ops/session`}
              element={<CreateSession />}
            />
            <Route
              path={`/ops/session-hq/:id`}
              element={<SessionWorkflow />}
            />
            <Route
              path={`/ops/session/:id`}
              element={<CreateSession />}
            />
            <Route
              path={`/ops/sessions`}
              element={<Sessions />}
            />
            <Route
              path={`/ops/wayfinder`}
              element={<Wayfinder />}
            />
            <Route
              path={`/ops/session-low-fa`}
              element={<SessionFreeAgentBreakdown />}
            />
            <Route
              path={`/ops/free-agent-teams`}
              element={<CreateIndyTeam />}
            />
            <Route
              path={`/ops/divisions`}
              element={<Divisions />}
            />
            <Route
              path={`/ops/division/:id`}
              element={<CreateDivision />}
            />
            <Route
              path={`/ops/division`}
              element={<CreateDivision />}
            />
            <Route
              path={`/ops/division/reseed/:id`}
              element={<ReseedDivisions />}
            />
            <Route
              path={`/ops/division/:id/schedule`}
              element={<ScheduleNew />}
            />
            <Route
              path={`/ops/tournaments`}
              element={<TournamentReport />}
            />
            <Route
              path={`/ops/tournament/:id`}
              element={<TournamentManagement />}
            />
            <Route
              path={`/ops/tournament/new`}
              element={<TournamentManagement />}
            />
            <Route
              path={`/ops/division/test`}
              element={<ScheduleTest />}
            />
            <Route
              path={`/ops/capacity-group/:id`}
              element={<CapacityGroup />}
            />
            <Route
              path={`/ops/capacity-group-overview`}
              element={<CapacityGroupOverview />}
            />
            <Route
              path={`/players/shopping-cart/:id`}
              element={<ShoppingCart />}
            />
            <Route
              path={`/players/waitlist`}
              element={<WaitList />}
            />
            <Route
              path={`/players/shopping-carts`}
              element={<ShoppingCarts />}
            />
            <Route
              path={`/Testing`}
              element={<TestPage />}
            />
            <Route
              path={`/staffing/my-shifts`}
              element={<MyShifts />}
            />
            <Route
              path={`/dev/legal-document-management`}
              element={<LegalDocumentManagement />}
            />
            <Route
              path={`/players/create-shopping-cart`}
              element={<CreateShoppingCart />}
            />
            <Route
              path={`/players/registrations`}
              element={<Registrations />}
            />
            <Route
              path={`/players/game-summary`}
              element={<GameSummary />}
            />
            <Route
              index
              element={<Base />}
            />
            <Route
              path="/reg-opening"
              element={<RegOpening />}
            />
          </Route>
          <Route
            path="/testPage"
            element={<TestPage />}
          />
          <Route
            path="/permissionDenied"
            element={<PermissionDenied />}
          />
          <Route
            path="/logout"
            element={<LogoutUser />}
          />
          <Route
            path="*"
            element={<Error />}
          />
        </Routes>
      </PageTitleContextProvider>
    </BrowserRouter>
  );
}

export default App;
